let Reveal = '';

export class loadingDialog {
	
	load() {
		if ( $('#rehd-modal').length == 0 ) {
			$( 'body' ).append( 
				$( '<div></div>', {
					"id": "rehd-modal",
					"class": "reveal small",
					"data-reveal":""
				})
			);
		}
		var modal_box = $( '#rehd-modal' );
		modal_box.html( "<p><i class='fal fa-arrows-rotate fa-spin fa-3x fa-fw' style='vertical-align: middle;'></i>&nbsp;Please wait...</p>" );
		Reveal = new Foundation.Reveal( modal_box, {} );
		Reveal.open();
		
		$('body').on('click', '.close-button', function(evclose) {
			Reveal.close();
		});
		
	}
	
	holding( html ) {
		var modal_box = $( '#rehd-modal' );
		modal_box.html( "<p><i class='fal fa-arrows-rotate fa-spin fa-3x fa-fw' style='vertical-align: middle;'></i>&nbsp;Please wait...</p>" );
	}
	
	content( html ) {
		var modal_box = $( '#rehd-modal' );
		modal_box.html( html );
	}
	
	large() {
		var modal_box = $( '#rehd-modal' );
		modal_box.removeClass( 'small' );
		modal_box.addClass( 'large' );
	}
	
	close() {
		Reveal.close();
	}

}