
export class formSubmit {
	
	load( selector, options ) {
		
		let settings = $.extend({
			// These are the defaults.
			file: [],
			cache: true,
			csrf: '',
			csrfname: '',
			datatype: 'html',
			callback: ''
		}, options);
		
		$( selector ).each( function( index, element ) {
			let $this = $( element );
			if ( typeof $this.data( 'cache' ) !== "undefined" ) {
				settings.cache = $this.data( 'cache' );
			}
			
			$this.on( 'submit', function( e ) {
				e.preventDefault();
			}).on( 'formvalid.zf.abide', function( e ) {
				$this.find( '.mce' ).each( function( i, el ) {
					let ed = tinyMCE.get( $( el ).prop( 'id' ) );
					if (typeof ed !== "undefined") {
						$(el).html(ed.getContent());
						tinyMCE.triggerSave();
					}
				});
				if ($this.find('.is-invalid-input').length > 0 ) {
					$('html, body').animate({
						scrollTop: ($this.find(".is-invalid-input").eq(0).offset().top-55)
					}, 1000);
					return false;
				}
				
				if (typeof $this.data('link') !== "undefined") {
					$('body').append($('<div></div>', {
						'id': "form-submit-modal",
						"class": "reveal small",
						"data-reveal":""
					}));
					
					let form_submit = $('#form-submit-modal');
					form_submit.html("<p><i class='fal fa-spinner-third fa-spin fa-3x fa-fw' style='vertical-align: middle;'></i>&nbsp;Loading. Please wait.</p>")
					let form_submit_modal = new Foundation.Reveal(form_submit, {});
					form_submit_modal.open();
					
					settings.csrf = $this.data('csrf');
					settings.csrfname = ($this.data('csrfname')) ? $this.data('csrfname') : "csrf_invalid";
					//file upload
					if ($this.find('input[type=file]').length > 0) {
						if ($this.find('[data-invalid]').length == 0) {
							// fetch FileList object
							form_submit.append("<p>If you're uploading files this may take some time.</p>");
							let formData = new FormData();
							for (let i = 0; i < $('input[type=file]').length; i++) {
								for (let k = 0; k < $('input[type=file]')[i].files.length; k++) {
									formData.append('files[]', $('input[type=file]')[i].files[k]);
									formData.append($('input[type=file]')[i].name, $('input[type=file]')[i].files[k]);
								}
							};
							let post_data = $this.serializeArray();
							$.each(post_data,function(key,input){
								formData.append(input.name,input.value);
							});
							formData.append("csrfname",settings.csrfname);
							formData.append(settings.csrfname,settings.csrf);
							$.ajax({
								url: $this.data('link'),
								data: formData,
								type: 'POST',
								dataType: ($this.data('type')) ? $this.data('type') : settings.datatype,
								contentType: false,
								cache: false,
								processData: false,
								success: function(msg) {
									if (typeof msg === "object") {
										if (msg.reload) {
											location.reload(true);
										}
										else if (msg.redirect) {
											window.location.href = msg.redirect;
										}
										else if (msg.html) {
											form_submit.html(msg.html);
										}
										else if ( msg.close ) {
											form_submit_modal.close();
										}
									}
									else {
										form_submit.html(msg);
										
										$this.find('input,textarea').not('[type=submit]').not('[type=hidden]').val("");
									}
								},
								complete: function(a,b) {
									jQuery('.close-reveal-modal, .close-reload').not(".button-no").on('click', function(evclose) {
										location.reload(true);
										evclose.preventDefault();
									});
									$('.button-no').on('click', function(evno) {
										form_submit.foundation('reveal', 'close');
										evno.preventDefault();
									});
									if (settings.callback != "") {
										settings.callback(a,b);
									}
								},
								error: function(a) {
									form_submit.html(a.responseText);
								}
							});
						}
					} else {
						$.ajax({
							url: $this.data('link'),
							data: $this.serialize()+"&csrfname="+settings.csrfname+"&"+settings.csrfname+"="+settings.csrf,
							type: 'POST',
							dataType: ($this.data('type')) ? $this.data('type') : settings.datatype,
							success: function(msg) {
								if (typeof msg === "object") {
									if (msg.reload) {
										location.reload(true);
									}
									else if (msg.redirect) {
										window.location.href = msg.redirect;
									}
									else if (msg.html) {
										form_submit.html(msg.html);
									}
									else if ( msg.close ) {
										form_submit_modal.close();
									}
								}
								else {
									form_submit.html(msg);
								}
							},
							complete: function(a,b) {
								$('.close-reveal-modal, .close-reload').not(".button-no").on('click', function(evclose) {
									location.reload(true);
									evclose.preventDefault();
								});
								$('.button-no').on('click', function(evno) {
									form_submit_modal.close();
									evno.preventDefault();
								});
								if (settings.callback != "") {
									settings.callback(a,b);
								}
							},
							error: function(a) {
								form_submit.html(a.responseText);
							},
							cache: settings.cache
						});
					}
				} else {
					this.submit();
				}
				e.preventDefault();
			}).on('forminvalid.zf.abide', function(e) {
				if ($this.find('.is-invalid-input').length > 0 ) {
					if ( !$this.find('.is-invalid-input').is(':visible') ) {
						let $id = $this.find('.is-invalid-input').eq(0).closest('.tabs-panel').attr('id');
						$('#'+$id+'-label').trigger('click');
					}
					$('html, body').animate({
						scrollTop: ($this.find(".is-invalid-input").eq(0).offset().top-55)
					}, 1000);
					return false;
				}
			});
		});
	}
}

