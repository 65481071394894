import 'what-input';
import { formSubmit } from './forms-es6.js';
import { DateTime } from "luxon";
import { loadingDialog } from './dialog.js';

import 'foundation-datepicker'; 

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
//window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

function check_other_field(
	$el,      /* jQuery element to validate */
	required, /* is the element required according to the `[required]` attribute */
	parent    /* parent of the jQuery element `$el` */
) {
	if ($('#'+$el.attr('data-other-field')).is(":checked") && !$el.val().length) {
		return false;
	}
	return true;
};
	
Foundation.Abide.defaults.patterns['google_analytics'] = /^\bUA-\d{4,10}-\d{1,4}\b$/;
Foundation.Abide.defaults.patterns['cms_password'] = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
Foundation.Abide.defaults.validators['other_radio_check'] = check_other_field;
Foundation.Abide.defaults.validators['tinymce'] = 
	function(element, required, parent) {
		// parameter 1 is jQuery selector
		if (!required) { return true; };
		var contentString = tinyMCE.get($(element).attr('id')).save();
		if (contentString.length > 0) {
			return true;
		}
		return false;
	  };
Foundation.Abide.defaults.patterns['google_authenticator_code'] = /^[0-9]{6}$/;
Foundation.Abide.defaults.patterns['hour_minute'] = /^(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])$/;
Foundation.Abide.defaults.patterns['price_amount'] = /^[0-9]*(\.[0-9]{2})$/;
Foundation.Abide.defaults.liveValidate = false;

$(document).foundation();

var $baseURL = $('base').attr('href');

$('button.enable-with-js[disabled]').removeAttr('disabled');

$('input[type=file]').on('change', function() {
	let $this = $(this),
		$label = $this.parent().find('label[for='+$this.attr('id')+']');
	
	$label.find('span').remove();
	$label.html($label.html()+'<span><br /><small>'+$this[0].files[0].name+'</small></span>');
});

if ( $.trim( $('.error-wrapper').html() ).length ) {
	$('.error-wrapper').removeClass( 'hide' );
}

if ( $.trim( $('.success-wrapper').html() ).length ) {
	$('.success-wrapper').removeClass( 'hide' );
}

if ( $('.ocfa-form').length ) {
	let $form = new formSubmit;
	$form.load( '.ocfa-form' );
}

if ( $('.ocfa-obj-form').length ) {
	let $form_venue = new formSubmit;
	let options = {
		datatype: 'json'
	};
	$form_venue.load( '.ocfa-obj-form', options );
}

if ( $('.ocfa-messaging-form').length ) {
	
	const nth = function( d ) {
		if (d > 3 && d < 21) return d+'th';
		switch (d % 10) {
			case 1:  return d+"st";
			case 2:  return d+"nd";
			case 3:  return d+"rd";
			default: return d+"th";
		}
	}
	
	function post_message_send( a, b ) {
		
		const dt = DateTime.now();
		
		$( '.message-list' ).append( "<li class='trainer'><b>" + nth( dt.toFormat( 'd' ) ) + dt.toFormat( ' LLLL y HH:mm' ) + "</b> - " + $( '.message-to-admin' ).val() + "<br /><small>You</small></li>" );
		$( '.message-to-send' ).val( '' );
		
	}
	
	let $form_msg = new formSubmit;
	let options = {
		callback: post_message_send,
		datatype: 'json'
	};
	$form_msg.load( '.ocfa-messaging-form', options );
}

let datepicker = $('.fdatepicker-input').fdatepicker({
	startView: 2,
	format: "dd/mm/yyyy",
	closeButton: true,
	closeIcon: '<i class="fa-solid fa-remove fa-xmark fi-x"></i>',
})
.data('datepicker');

$( '.fa-magnifying-glass-arrow-right').on( 'click', function() {
	$('.magnifying-glass-open').toggleClass( 'hide' );
});

let refreshIntervalId = setInterval( () => {
	if ( $('body.logged-in').length ) {
		$.ajax({
			url: '/ocfa/cms/data/ajaxrequests/login/check-login-status.php',
			data: {},
			type: 'POST',
			dataType: "json",
			success: function(data) {
				if ( !data.status ) {
					let $dialog = new loadingDialog;
					$dialog.load();
					
					$dialog.content( '<p>You session has been inactive for too long and you have been logged out. Please login again to continue</p><p><a href="/admin/" class="button">Admin Login</a> &nbsp;<a href="/trainer/" class="button">Trainer Login</a></p><button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>' );
					
					$('body').addClass('logged-out').removeClass('logged-in');
					$('body').find('.content-wrapper').remove();
				}
			}
		});
	}
	else {
		clearInterval(refreshIntervalId);
	}
}, 60000 );